/* You can add global styles to this file, and also import other style files */

.content {
  margin-top: 30px;
  margin-bottom: 20px;
}

html {
  padding: 0;
  margin: 0;
  color: #000;
  background-color: #e0e0e0;
}

body {
  font-family: zawgyi, Roboto, Arial, Georgia, 'Times New Roman', Times, serif !important;
  color: #000 !important;
  padding: 0 !important;
  margin: 0 auto !important;
  width: 100vw !important;
  max-width: 470px !important;
}