button {
  margin-right: 10px;
  margin-top: 10px;
  width: 100%;
}

.logo {
  width: 100px;
  height: 100px;
}

.pay-image {
  width: 75px;
  height: 75px;
}

#webapp-wrapper {
  overflow: hidden;
  text-align: center;
  position: relative;
  min-height: 100vh;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 0.8) 55%,
    rgba(0, 0, 0, 0.5) 60%,
    rgba(0, 0, 0, 0.9) 100%
  );
}

#webapp-wrapper #webapp-content {
  min-height: 100vh;
  overflow: hidden;
  z-index: 99;
  background-color: #fff;
}

#webapp-wrapper .content-block {
  background-color: #0a3361;
  padding: 9px 0;
}

div.content-header.contact-header {
  height: 100px;
  width: 50%;
  background-color: #191970;
}

div.content-header.contact-header {
  height: 100px;
  width: 100%;
  background-color: #191970;
}

.logo-img {
  height: 100px;
  width: 20%;
  margin: 0px 10px 75px 20px;
}

.slogan-img {
  height: 70px;
  width: 40%;
  margin: 0px 10px 85px 100px;
}

.phshop {
  text-align: center;
  margin: 0;
  display: block;
  border-bottom: 1px grey solid;
}

.phshop-img {
  float: left;
  left: 27%;
  margin-top: -1%;
  width: 10%;
  position: relative;
}

.phshop-text {
  /* position: relative;*/
  margin-top: 4%;
  font-size: 1em;
  font-weight: bold;
}

hr.phshop-break {
  margin-top: -1%;
}

.pay-info {
  width: 100%;
  font-size: 0.8em;
  text-align: left;
  margin: 8%;
}

h2 {
  border: 1px solid;
  margin: 3%;
  padding: 3%;
  padding: 6px 12px;
  margin-bottom: 2em;
  color: #0a3361;
  background: #a2a0a0;
}

.content-pay-plan {
  width: 100%;
  font-size: 0.8em;
  text-align: left;
  margin: 0 8%;
}

.qrscan {
  height: auto;
  width: 100%;
}

.qrscan-image {
  margin: 10px 10px 10px 10px;
}

.location_tbl_2c2p {
  background: #ecebeb;
  margin: 2%;
}

.location_tbl_2c2p img {
  width: 100%;
}

.location_tbl_2c2p td {
  width: 33.33%;
}

.content-thank {
  width: 100%;
  text-align: center;
  font-weight: bold;
  margin: 12px 0px 0px 0px;
}

hr.total-break {
  width: 68%;
  margin-left: -10px;
  border-top: dotted 2px;
  color: #28aae0;
}

.payment_inscruction_2c2p,
.payment_inscruction_qr {
  text-align: left;
  margin-left: 25%;
  margin-top: 7%;
  margin-bottom: 5%;
  font-size: 1em;
}

.ez-qr-logo img {
  width: 40%;
  margin: 4%;
}

table.pay-info td:last-child,
table.content-pay-plan td:last-child {
  padding: 0 10px;
}

.payment_tab {
  overflow: hidden;
  margin-top: 1em;
  padding: 5%;
}

.btn_makepayment {
  background-color: #28aae0;
  border: none;
  color: white;
  padding: 10px 50px 10px 50px;
  text-align: center;
  font-weight: bold;
  display: inline-block;
  font-size: 16px;
  border-radius: 25px;
  margin: 0 auto;
}

.back_payment {
  overflow: hidden;
  margin: 5% 0% 10% 0%;
  padding: 5%;
}

.btn_back_payment {
  background-color: #28aae0;
  border: none;
  color: white;
  padding: 10px 50px 10px 50px;
  text-align: center;
  font-weight: bold;
  display: inline-block;
  font-size: 16px;
  border-radius: 25px;
  margin: 0 auto;
}

.tab {
  overflow: hidden;
  margin-top: 1em;
}

.tab a {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 5px 5px 5px 5px;
  transition: 0.3s;
  font-size: 17px;
  width: 19%;
}

.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
  width: 48%;
}

.tab button:hover {
  background-color: #ddd;
}

.tab button.active {
  background-color: #ccc;
}

.tabcontent {
  padding: 6px 12px;
  border-top: none;
  margin-bottom: 2em;
  border-bottom: 1px solid #c1c1c1;
  color: #0a3361;
  background: #a2a0a0;
}

.tablinks_master {
  width: 84%;
  border: 2px solid #e0cece;
}

.tablinks_visa {
  width: 86%;
  border: 2px solid #e0cece;
}

.tablinks_jcb {
  width: 85%;
  margin-top: 3%;
  border: 2px solid #e0cece;
}

.tablinks_mpu {
  width: 88%;
  margin-top: -12%;
}

.tablinks_gng {
  width: 78%;
  height: 8%;
  border: 2px solid #e0cece;
}

.tablinks_ezdingar {
  width: 80%;
  height: 8%;
  border: 2px solid #e0cece;
}

.tablinks_cb_agent {
  width: 85%;
  height: 6.5%;
  margin-top: 10%;
}

.tablinks_ayamobile {
  width: 80%;
  height: 8.5%;
}

.tablinks_kbz {
  width: 85%;
  height: 6.5%;
}

.tablinks_aya {
  width: 85%;
  height: 6%;
  border: 2px solid #e89191;
}

.tablinks_cb {
  width: 85%;
  height: 6.5%;
}

.tablinks_mab {
  width: 80%;
  height: 6%;
  border: 2px solid #e24f4f;
}

.tablinks_uab {
  width: 85%;
  height: 6.5%;
}

.tablinks_wave {
  width: 85%;
  height: 6%;
  border: 2px solid #24a4d8;
}

.tablinks_card {
  width: 20%;
}

.content-item span {
  position: relative;
  display: block;
}

#paid_tab {
  margin-top: 4%;
}

.paid_table td,
.unpaid_table td {
  padding: 8px;
  text-align: center;
}

.paid_table th,
.unpaid_table th {
  padding: 10px;
}

.contact-header a {
  float: left;
  left: 0em;
  position: relative;
  width: 9%;
  top: 1.3em;
}

.paid_table td,
.paid_table th,
.unpaid_table td,
.unpaid_table th {
  border: 1px solid #ddd;
  padding: 4px;
}

.paid_table td,
.unpaid_table td {
  text-align: center;
}

.paid_table,
.unpaid_table {
  border-collapse: collapse;
  width: 90%;
  left: 1em;
  position: relative;
}

.paid_table th,
.unpaid_table th {
  background: #95c3db;
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  color: white;
}

.paid_table tr:nth-child(even),
.unpaid_table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.modal-content {
  background: url(https://horizoninternet.myanmaronlinecreations.com/sites/all/modules/hti/images/invoice_popup_bg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  z-index: 99;
}

.modal-inline {
  width: 90%;
  height: 70vh;
  border-radius: 5px;
  padding: 3em 1em 2em 1em;
  text-align: center;
}

h3.popup-title {
  background: none;
  border: none;
  margin: 1em 0em 2em 0em;
  color: #0a3361;
  font-family: cursive;
}

#btnok {
  background-color: #28aae0;
  border: none;
  color: white;
  padding: 0.5em 3em;
  border: 1px solid #28aae0;
  text-decoration: none;
  font-size: 1em;
  border-radius: 5px;
  margin-top: 2vw;
}

.payment_method_content {
  margin: 1% 5% 1% 10%;
}

.pay_cards h3,
.pay_direct h3,
.pay_agent h3,
.direct_transfer h3 {
  font-size: 1em;
  float: left;
  margin: 0%;
}

.pay_cards,
.pay_direct,
.pay_agent,
.direct_transfer {
  margin: 15px 0px 0px 0px;
}

.tablinks img {
  border-radius: 7px;
}

.transfer_account {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 15px 5px 5px 10px;
  transition: 0.3s;
  font-size: 15px;
  text-align: left;
  font-weight: bold;
}

.transfer_aya {
  width: 80%;
  height: 8%;
  border: 2px solid #a97b7b;
}

.transfer_kbz {
  width: 80%;
  height: 8%;
  border: 2px solid #a97b7b;
}

.transfer_cb {
  width: 77%;
  height: 7%;
  border: 2px solid #a97b7b;
  padding: 10% 0% 0% 5%;
}

.pay_footer {
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: #2f2b54;
  color: white;
  text-align: center;
  padding: 0;
  margin: 0 auto;
  width: 100vw;
  max-width: 470px;
  height: 5%;
}
